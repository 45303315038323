import React from 'react'
import * as S from './styles'

import { T } from '@tolgee/react'

import ReactIcon from '../../assets/react.svg'
import Typescript from '../../assets/typescript.svg'

export default function Icons() {
  return (
    <S.Footer>
      <span>
        <T keyName='desenvolvido_por'>Desenvolvido por</T>
        <b>Gustavo Scarpim</b>
        <T keyName='utilizando'>utilizando </T>
        <img src={ReactIcon} alt='Icone React' />
        <T keyName='com'>com</T>
        <img alt='Typescript' src={Typescript} />
      </span>
    </S.Footer>
  )
}
