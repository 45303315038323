import React from 'react';
import * as S from './styles';

import { T } from '@tolgee/react';

import HelloGif from '../../assets/hello.gif';
import Musico from '../../assets/trompete.svg';
import ReactIcon from '../../assets/react.svg';
import Xadrez from '../../assets/xadrez.svg';

import Footer from '../../components/footer/footer';
import Icons from '../../components/icons/Icons';
import Skills from '../../components/skills/Skills';

export default function Sobre() {
  return (
    <>
      <S.Container>
        <S.Content>
          <S.ContentPerfil>
            <S.Perfil>
              <img
                alt='Gustavo Scarpim'
                src='https://avatars1.githubusercontent.com/u/47616551?s=460&u=f9f81df034252d349d5c631a85cd57ea603beefa&v=4'
              />

              <label>Gustavo Scarpim</label>

              <S.Icons>
                <Icons />
              </S.Icons>

              <S.ContentMe>
                <span>
                  <T keyName='desenvolvedor'>Desenvolvedor</T> Front End
                </span>
                <img src={ReactIcon} alt='Icone React' />
              </S.ContentMe>

              <S.Skills>
                <label>
                  <T keyName='minhas_skills'>Minhas Skills</T>
                </label>
                <hr />
                <Skills />
              </S.Skills>
            </S.Perfil>
          </S.ContentPerfil>

          <S.ContentDescription>
            <S.Description>
              <h2>
                <img src={HelloGif} width='28px' alt='Gif olá' />{' '}
                <T keyName='meu_nome_e'>Olá, meu nome é</T>
                <b> Gustavo Scarpim</b>!
              </h2>

              <p>
                <T keyName='tenho_22_anos'>
                  Tenho 22 anos, moro na cidade de São Paulo - Capital, cursando
                  o último semestre de
                </T>{' '}
                <b>
                  {' '}
                  <T keyName='ciencia_da_computacao'>Ciência da Computação</T>
                </b>{' '}
                <T keyName='na'>na</T> Universidade Cruzeiro do Sul.
                <p>
                  {' '}
                  <T keyName='apaixonado_tecnologia'>
                    Apaixonado por tecnologia, arte, xadrez e música, obtive uma
                    boa afinidade com a área da programação. Sou
                  </T>
                  <b>
                    {' '}
                    <T keyName='desenvolvedor'>desenvolvedor</T> FrontEnd
                  </b>{' '}
                  <T keyName='com_muito_orgulho'>com muito orgulho</T>,
                  <img src={Xadrez} alt='Xadrez' width='20px' />{' '}
                  <T keyName='enxadrista'>enxadrista</T> ,{' '}
                  <img src={Musico} alt='Musico' width='20px' />{' '}
                  <T keyName='musico'>e músico</T>.
                </p>
              </p>

              <h2>
                <T keyName='historico_profissional'>Histórico profissional</T>
              </h2>

              <p>
                <T keyName='iniciei_programacao'>
                  Iniciei na programação em 2016 com alguns cursos da internet,
                  no ano de 2018 dei inicio na faculdade de
                </T>{' '}
                <b>
                  <T keyName='ciencia_da_computacao'>Ciência da computação</T>
                </b>{' '}
                ,{' '}
                <T keyName='aprendi_muita_coisa'>
                  aprendi muita coisa na faculdade e nos cursos que realizei por
                  fora, logo, em 2019 consegui o meu primeiro estágio na área de
                </T>{' '}
                <b>
                  <T keyName='desenvolvimento'>desenvolvimento</T>
                </b>{' '}
                <T keyName='na_empresa'>na empresa</T> <b>ABBC</b>{' '}
                <T keyName='ramo_financeiro'>do ramo financeiro.</T>
                <p>
                  <T keyName='no_estagio'>
                    No estágio eu trabalhei com desenvolvimento
                  </T>{' '}
                  <b>Front-End | Backend</b>,{' '}
                  <T keyName='utilizando_linguagens'>
                    utilizando as linguagens ReactJs, NodeJs, Javascript, C#,
                    .Net Core, SQL e Mongo DB. As minhas principais atividades
                    dentro da empresa eram criar
                  </T>{' '}
                  <b>
                    <T keyName='sistemas_web'>sistemas web</T>
                  </b>{' '}
                  <T keyName='para_melhorias'>
                    para melhorias internas da empresa
                  </T>
                  .
                </p>
                <p>
                  <T keyName='no_fim_estagio'>
                    No fim do meu estágio em 2020, fui para uma agência
                    publicitária chamada
                  </T>{' '}
                  <b>Hyst</b>{' '}
                  <T keyName='tambem_ramo_financeiro'>
                    também do ramo financeiro, atuando como
                  </T>
                  <b>
                    {' '}
                    <T keyName='desenvolvedor'>Desenvolvedor</T> Front-End
                  </b>
                  ,{' '}
                  <T keyName='linguagens_utilizei'>
                    as linguagens que utilizei na
                  </T>{' '}
                  <b>Hyst</b>{' '}
                  <T keyName='sao_tambem_acumulei'>
                    são ReactJs, VueJs, NextJs, Sass, Redux, e também acumulei
                    diversas skills de
                  </T>{' '}
                  <b>Front-End</b> <T keyName='e'>e</T> <b>UX Design</b>.
                </p>
                <p>
                  <T keyName='em'>Em</T> <b>2021</b>{' '}
                  <T keyName='tive_oportunidade'>
                    tive a oportunidade de estar atuando como
                  </T>{' '}
                  <b>
                    <T keyName='desenvolvedor'>desenvolvedor</T> Front-End Pleno
                  </b>{' '}
                  <T keyName='na_empresa'>na empresa</T> <b>Evnts</b>{' '}
                  <T keyName='ramo_eventos'>
                    do ramo de eventos, nessa empresa eu trabalho com ReactJs
                    dando manutenção e desenvolvendo novas melhorias no sistema
                    da empresa.
                  </T>
                </p>
              </p>

              <h2>
                <T keyName='trabalhos_freelancer'>Trabalhos como Freelancer</T>
              </h2>

              <p>
                <T keyName='decorrer_carreira'>
                  No decorrer da minha carreira eu trabalhei nas seguintes
                  empresas como freelancer:
                </T>{' '}
                <b>
                  <T keyName='empresas_freelas'>
                    Sambatech, EnjoyTix, Alsofer, Guardiões de vidas, Etio
                    soluções e Evnts.
                  </T>
                </b>{' '}
              </p>

              <h2>
                <T keyName='curiosidades_hobbies'>Curiosidades e Hobbies</T>
              </h2>

              <p>
                <T keyName='uma_das_coisas'>
                  Uma das coisas que mais gosto de fazer no meu tempo livre é
                </T>{' '}
                <b>
                  <T keyName='estudar'>estudar</T>
                </b>{' '}
                <T keyName='coisas_novas'>
                  coisas novas, sempre que aprendo algo eu crio um repositório
                  no
                </T>
                <b> GitHub</b>{' '}
                <T keyName='para_fazer_projeto'>
                  para fazer um projeto e assim colocar aquilo que estudei em
                  prática.
                </T>
                <p>
                  <T keyName='alem_estudar'>
                    Além de estudar eu curto uma "partidinha" de
                  </T>{' '}
                  <b>Xadrez</b>{' '}
                  <T keyName='participei_diversos_torneios'>
                    e já participei de diversos torneios. Aprendi muito cedo a
                    tocar um instrumento chamado
                  </T>
                  <i>
                    {' '}
                    <b>
                      <T keyName='eufonio'>eufônio</T>
                    </b>
                  </i>{' '}
                  <T keyName='desde_entao'>
                    e desde então, sempre que posso, estou tocando ele.
                  </T>
                </p>
              </p>
            </S.Description>
          </S.ContentDescription>
        </S.Content>
      </S.Container>
      <Footer />
    </>
  )
}
