/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from 'react'

import { T } from '@tolgee/react'

import * as S from './styles'

import Footer from '../../components/footer/footer'
import HrefContext from '../../context/Href'
import { projetosDefault } from './projetos'

function Projetos() {
  const [href, setHref] = useState<string>('')
  const [projects] = useState<any[]>(projetosDefault)

  useEffect(() => {
    var href = window.location.pathname
    handleSubmit(href)
  }, [])

  //usando o useContext para capturar as propriedades do UserContext
  const { setState: setGlobalState } = useContext(HrefContext)

  //função que ira submeter a alteração do estado global da aplicação
  function handleSubmit(endpoint?: any) {
    setHref(endpoint)
    setGlobalState({ href })
  }

  return (
    <>
      <S.Container>
        {projects.map((item, index) => (
          <>
            {item.reverse === false ? (
              <S.Card width={item.width}>
                <div className='div1'>
                  <h1>{item.title}</h1>
                  <span>{item.description}</span>
                  <a rel="noreferrer" href={item.linkGithub} target='_blank'>
                    {item.code ? (
                      <T keyName='ver_codigo'>Ver código</T>
                    ) : (
                      <T keyName='ver_site'>Ver site</T>
                    )}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z' />
                    </svg>
                  </a>
                </div>
                <a rel="noreferrer" href={item.linkProjeto} target='_blank' className='div2'>
                  <img src={item.linkGif} alt='Projeto' />
                </a>
              </S.Card>
            ) : (
              <S.CardReverse width={item.width}>
                <a rel="noreferrer" href={item.linkProjeto} target='_blank' className='div2'>
                  <img src={item.linkGif} alt='Projeto' />
                </a>
                <div className='div3'>
                  <h1>{item.title}</h1>
                  <span>{item.description}</span>
                  <a rel="noreferrer" href={item.linkGithub} target='_blank'>
                    {item.code ? (
                      <T keyName='ver_codigo'>Ver código</T>
                    ) : (
                      <T keyName='ver_site'>Ver site</T>
                    )}

                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z' />
                    </svg>
                  </a>
                </div>
              </S.CardReverse>
            )}
          </>
        ))}
      </S.Container>
      <Footer />
    </>
  )
}

export default Projetos
