import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import GlobalStyles from './globalStyles'

import NavBar from './components/menu/Navbar'
import Loading from './components/loading'

import { TolgeeProvider } from '@tolgee/react'
import { UI } from '@tolgee/ui'

ReactDOM.render(
  <React.StrictMode>
    <TolgeeProvider
      filesUrlPrefix='i18n/'
      apiUrl={process.env.REACT_APP_TOLGEE_API_URL}
      apiKey={process.env.REACT_APP_TOLGEE_API_KEY}
      ui={process.env.REACT_APP_TOLGEE_API_KEY ? UI : undefined}
      loadingFallback={<Loading />}
    >
      <GlobalStyles />
      <NavBar />
    </TolgeeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
