import React from 'react';

import { useSetLanguage, useCurrentLanguage } from '@tolgee/react';

import { Container } from './styles'

const LangSelector = () => {
  const setLanguage = useSetLanguage();
  const getLanguage = useCurrentLanguage();

  return (
    <Container>
      <select
        onChange={(e) => setLanguage(e.target.value)}
        value={getLanguage()}
      >
        <option value='pt'>🇧🇷 Português</option>
        <option value='en'>🇬🇧 English</option>
        <option value='es'>🇪🇸 Espanhol</option>
      </select>
    </Container>
  );
};

export default LangSelector;
