import { useContext, useState } from 'react';

import { T } from '@tolgee/react';

import * as S from './styles';

import LangSelector from '../langSelector';

import Sobre from '../../pages/sobre/Sobre';
import Projetos from '../../pages/projetos/Projetos';

import HrefContext from '../../context/Href';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

type Props = {
  open: boolean;
}

function RightNav({ open }: Props) {
  const [href, setHref] = useState<string>('');

  const { setState: setGlobalState } = useContext(HrefContext);

  function handleSubmit(endpoint?: any) {
    setHref(endpoint);
    setGlobalState({ href });
  }

  return (
    <>
      <LangSelector />
      <Router>
        <S.Ul open={open} href={href}>
          <li>
            <Link to='/' onClick={() => handleSubmit('/')} className='LinkHome'>
              <T keyName='sobre'>Home</T>
            </Link>
          </li>
          <li>
            <Link
              to='/projetos'
              onClick={() => handleSubmit('projetos')}
              className='LinkProjetos'
            >
              <T keyName='projetos'>Projetos</T>
            </Link>
          </li>
        </S.Ul>

        <Switch>
          <Route exact path='/'>
            <Sobre />
          </Route>
          <Route exact path='/projetos'>
            <Projetos />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default RightNav
