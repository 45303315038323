import { T } from '@tolgee/react'

export const projetosDefault = [
  {
    "title": "Bem-te-vi",
    "description": <><T keyName='animando_svg'>Animando um SVG utilizando</T> <b>GSAP</b> <T keyName='junto_com'> junto com</T> <b>React</b></>,
    "linkGithub": "https://github.com/GuScarpim/bird-animation",
    "linkProjeto": "https://bem-te-vi.netlify.app/",
    "linkGif": "https://github.com/GuScarpim/bird-animation/raw/master/src/Assets/bem-te-vi.gif",
    "width": "",
    "reverse": true,
    "code": true
  },
  {
    "title": "Cat Animation - GSAP",
    "description": <><T keyName='animando_svg'>Animando um SVG utilizando</T> <b>GSAP</b> <T keyName='junto_com'> junto com</T> <b>React</b></>,
    "linkGithub": "https://github.com/GuScarpim/Cat-Animation",
    "linkProjeto": "https://cat-animation.netlify.app/",
    "linkGif": "https://dev-to-uploads.s3.amazonaws.com/uploads/articles/kxy0wnii3wnw800jk43t.gif",
    "width": "",
    "reverse": false,
    "code": true
  },
  {
    "title": "Etio Soluções",
    "description": <><T keyName='site_desenv_etio'>Site desenvolvido para a empresa Etio Soluções</T>.</>,
    "linkGithub": "https://etiosolucoes.netlify.app/",
    "linkProjeto": "https://etiosolucoes.netlify.app/",
    "linkGif": "https://s9.gifyu.com/images/etios.gif",
    "width": "",
    "reverse": true,
    "code": false
  },
  {
    "title": "IT Forever - Front",
    "description": <>
      <T keyName='projeto_desenv_sempre_it'>Projeto desenvolvido para realizar um teste da empresa</T> <b>Sempre IT</b>, {' '}
      <T keyName='desafio_criar_crud'>o desafio era criar um CRUD com autenticação completo, foi utilizado</T> <b>ReactJS</b>, <b>Typescript</b>, <b>Styled Components</b> {' '}
      <T keyName='para_desenvolver'>para desenvolver o</T> <b>front</b>, <T keyName='e'>e</T> <b>NodeJS</b> <T keyName='com'>com</T>{' '}
      <b>Mysql</b> <T keyName='para_fazer'>para fazer a</T> <b>API</b>.
    </>,
    "linkGithub": "https://github.com/GuScarpim/IT-Forever",
    "linkProjeto": "https://github.com/GuScarpim/IT-Forever",
    "linkGif": "https://github.com/GuScarpim/IT-Forever/blob/main/src/Assets/IT_forever.gif?raw=true",
    "width": "",
    "reverse": false,
    "code": true
  },
  {
    "title": "IT Forever - Back",
    "description": <>
      <T keyName='backend_do_projeto'>Backend do projeto</T> <b>Sempre IT</b>. {' '}
      <T keyName='nesse_projeto_foi'>Nesse projeto foi desenvolvido uma</T> <b>API</b> {' '}
      <T keyName='para_integrar_com_front'>para integrar com o meu front. Desenvolvi um</T> <b>CRUD</b> {' '}
      <T keyName='com_validacao_autorizacao'>com validação e autorização utilizando</T> <b>JWT</b>, <b>NodeJS</b>, <b>Javascript</b> {' '}
      <T keyName='persistindo_dados'>e persistindo os dados no banco</T> <b>Mysql</b>.</>,
    "linkGithub": "https://github.com/GuScarpim/IT-Forever-Back",
    "linkProjeto": "https://github.com/GuScarpim/IT-Forever-Back",
    "linkGif": "https://github.com/GuScarpim/IT-Forever/raw/main/src/Assets/IT_foreverMobile.gif",
    "width": "250",
    "reverse": true,
    "code": true
  },
  {
    "title": "Upload de imagem Base64",
    "description": <>
      <T keyName='persistindo_dados'>Projeto desenvolvido em</T> <b>React</b> {' '}
      <T keyName='e'>e</T> <b>Typescript</b> {' '}
      <T keyName='para_mostrar_como_fazer'>para mostrar como fazer um upload de imagem no formato base64 que é geralmente usado quando se trata de upload</T>.
    </>,
    "linkGithub": "https://github.com/GuScarpim/upload-image-react-base64",
    "linkProjeto": "https://guscarpim.github.io/upload-image-react-base64/",
    "linkGif": "https://github.com/GuScarpim/upload-image-react-base64/raw/main/front/src/assets/upload.gif",
    "width": "",
    "reverse": false,
    "code": true
  },
  {
    "title": "Meu primeiro Portfólio",
    "description": <>
      <T keyName='primeiro_portfolio'>Esse foi o meu primeiro portfólio desenvolvido para mostrar o meu trabalho e falar um pouco sobre mim. O portfólio foi desenvolvido utilizando as linguagens</T> {' '}
      <b>ReactJS</b> <T keyName='com'>com</T> <b>Styled Components</b>.</>,
    "linkGithub": "https://github.com/GuScarpim/Portfolio",
    "linkProjeto": "https://guscarpim.github.io/Portfolio/",
    "linkGif": "https://raw.githubusercontent.com/GuScarpim/Portfolio/master/src/assets/portfolio.gif",
    "width": "",
    "reverse": false,
    "code": true
  },
  {
    "title": "Clone do WhatsApp",
    "description": <>
      <T keyName='esse_projeto_whatsapp'>Esse projeto foi desenvolvido para demonstrar como fazer um clone do WhatsApp utilizando as linguagens</T> {' '}
      <b>ReactJs</b>, <b>Typescript</b> <T keyName='com'>com</T> <b>Styled Components</b>.
    </>,
    "linkGithub": "https://github.com/GuScarpim/Clone_WhatsApp",
    "linkProjeto": "https://github.com/GuScarpim/Clone_WhatsApp",
    "linkGif": "https://raw.githubusercontent.com/GuScarpim/Clone_WhatsApp/main/front/src/Assets/gif.gif",
    "width": "",
    "reverse": true,
    "code": true
  },
  {
    "title": "Buscar Cep React",
    "description": <>
      <T keyName='buscar_cep_foi'>Buscar Cep foi uma idéia que eu tive quando comecei a programar em</T> {' '}
      <b>React</b>, <T keyName='nele_foi_feito'>nele foi feito</T> {' '}
      <b><T keyName='consumo_api'>consumo de API</T></b>, {' '}
      <T keyName='populando_dados_dentro'>e populando dados dentro do </T> <b>select</b> {' '}
      <T keyName='e'>e</T> <b><T keyName='tabela'>tabela</T></b>. {' '}
      <T keyName='fiz_projeto_porque_percebi'>Fiz esse projeto porque, percebi que muita gente que está começando a programar em</T> <b>React</b> {' '}
      <T keyName='tem_dificuldades_com_algo'>tem dificuldades com alguma dessas coisas, e deixei esse projeto</T> <b>open source</b> {' '}
      <T keyName='para_poder_ajudar'>para poder ajudar outros programadores</T>. {' '}
    </>,
    "linkGithub": "https://github.com/GuScarpim/BuscarCep-React",
    "linkProjeto": "https://guscarpim.github.io/BuscarCep-React/index",
    "linkGif": "https://user-images.githubusercontent.com/47616551/73510308-742b5480-43c0-11ea-995e-cfed2c876874.gif",
    "width": "",
    "reverse": false,
    "code": true
  },
  {
    "title": "Filmes KRS",
    "description": <>
      <T keyName='esse_foi_desafio_krs'>Esse foi um desafio de uma empresa chamada</T> <b>KRS</b>, {' '}
      <T keyName='desafio_era_criar'>o desafio era criar um sistema de locadora onde era possível, cadastrar, editar, excluir e visualizar filmes, além de desenvolver o</T> {' '}
      <b>Front</b> <T keyName='com'>com</T> <b>ReactJs</b> {' '}
      <T keyName='tambem_desenvolvi'>eu também desenvolvi uma</T> <b>API</b> {' '}
      <T keyName='utilizando'>utilizando</T> <b>NodeJS</b> <T keyName='com'>com</T> <b>MongoDB</b> {' '}
      <T keyName='para_fazer_as_funcionalidades'>para fazer as funcionalidades do Crud</T>.
    </>,
    "linkGithub": "https://github.com/GuScarpim/Filmes_KRS",
    "linkProjeto": "https://github.com/GuScarpim/Filmes_KRS",
    "linkGif": "https://raw.githubusercontent.com/GuScarpim/Filmes_KRS/master/web/src/assets/locadora.gif",
    "width": "",
    "reverse": true,
    "code": true
  },
  {
    "title": "Private Route React",
    "description": <>
      <T keyName='private_route_e_um'>Private Route é um projeto desenvolvido por mim desde o</T> {' '}
      <b>Backend</b> <T keyName='utilizando'>utilizando</T> <b>NodeJs</b> <T keyName='com'>com</T> <b>MongoDB</b> {' '}
      <T keyName='ate_o'>até o</T> <b>Front</b> <T keyName='utilizando'>utilizando</T> <b>ReactJS</b>
    </>,
    "linkGithub": "https://github.com/GuScarpim/React-Login-PrivateRoute",
    "linkProjeto": "https://github.com/GuScarpim/React-Login-PrivateRoute",
    "linkGif": "https://raw.githubusercontent.com/GuScarpim/React-Login-PrivateRoute/master/myapp/src/img/ReactLoginGuScarpim.gif",
    "width": "",
    "reverse": false,
    "code": true
  },
  {
    "title": "Vue Search Repos",
    "description": <>
      <T keyName='esse_projeto_desenvolvido'>Esse projeto foi desenvolvido em</T> <b>VueJs</b> {' '}
      <T keyName='consumindo_uma'>consumindo uma</T> <b>API</b> <T keyName='do'>do</T> <b>GitHub</b>, {' '}
      <T keyName='esse_projeto_e_para'>esse projeto é para mostrar como fazer uma requisição para pesquisar usuarios e os seus repositórios do</T> {' '}
      <b>GitHub</b> <T keyName='utilizando'>utilizando</T> VueJS.
    </>,
    "linkGithub": "https://github.com/GuScarpim/Vue_Search_Repos",
    "linkProjeto": "https://guscarpim.github.io/Vue_Search_Repos/",
    "linkGif": "https://raw.githubusercontent.com/GuScarpim/Vue_Search_Repos/master/src/assets/vue.gif",
    "width": "",
    "reverse": true,
    "code": true
  },
  {
    "title": ".Net Core API - AD Group",
    "description": <>
      <T keyName='api_em_dotnet'>API em DotNet Core demonstrando uma maneira de fazer autenticação por grupos do AD do windows</T>.
    </>,
    "linkGithub": "https://github.com/GuScarpim/.NetCoreAPI_AD_Group",
    "linkProjeto": "https://github.com/GuScarpim/.NetCoreAPI_AD_Group",
    "linkGif": "https://guscarpim.github.io/Portfolio/static/media/api.b92871a7.gif",
    "width": "",
    "reverse": false,
    "code": true
  },
  {
    "title": "Next Google",
    "description": <>
      <T keyName='intuito_desse_projeto'>O intuito desse projeto é demonstrar como fazer uma tela de login utilizando a autenticação do</T> {' '}
      <b>Google</b>, <T keyName='alem_de_guardar'>além de guardar o </T> <b>Token</b> {' '}
      <T keyName='no'>no</T> <b>Local Storage</b> {' '}
      <T keyName='ele_tambem_nao_permite'>ele também não permite avançar para a próxima página sem estar logado. Projeto desenvolvido com</T> {' '}
      <b>NextJs</b> <T keyName='e'>e</T> <b>ReactJs</b>.
    </>,
    "linkGithub": "https://github.com/GuScarpim/Next_Google_Route_Private",
    "linkProjeto": "https://github.com/GuScarpim/Next_Google_Route_Private",
    "linkGif": "https://raw.githubusercontent.com/GuScarpim/Next_Google_Route_Private/master/public/images/next_google.gif",
    "width": "",
    "reverse": false,
    "code": true
  }
]

export default projetosDefault