import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 87px;
  right: 20px;
  z-index: 9;

  select {
    font-family: 'Zilla Slab', sans-serif;
    padding: 3px;
    outline: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  }

  input {
    border-radius: 100%;
  }
`