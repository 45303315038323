import { useState } from 'react';
import React from 'react';

import Burger from './Burger';
import Arrow from '../../assets/arrow.svg';

import * as S from './styles';

type Props = {
  children?: any;
};

export default function Navbar(props: Props) {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', checkScrollTop);

  return (
    <>
      <S.Nav>
        <div className='logo'>
          <div className='flex'>
            <span>GUSTAVO SCARPIM</span>
          </div>
        </div>
        <div className='custom-shape'>
          <svg
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1200 120'
            preserveAspectRatio='none'
          >
            <path
              d='M649.97 0L550.03 0 599.91 54.12 649.97 0z'
              className='shape-fill'
            ></path>
          </svg>
        </div>
      </S.Nav>
      <Burger />
      <S.Button
        onClick={scrollTop}
        style={{ height: 40, display: showScroll ? 'flex' : 'none' }}
      >
        <img src={Arrow} alt='Go to Top' />
      </S.Button>
      {props.children}
    </>
  );
}
